<template>
  <div>
    <!-- 卡片 -->
    <el-card class="box-card">
      <!-- 添加按钮 -->
      <el-button type="primary" size="small" @click="teacherShow = true">添加课程分类</el-button>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <!-- <el-table-column align="center" type="index" label="序号" width="100"></el-table-column> -->
        <el-table-column align="center" label="id" prop="id" width="150"></el-table-column>
        <el-table-column align="center" label="名称" prop="sortName"></el-table-column>
        <el-table-column align="center" width="150" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.status == 0 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250">
          <template slot-scope="scope">
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button type="danger" @click="handlerRemove(scope.row)" icon="el-icon-delete" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]"
                     :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>

    <!-- 添加对话框 -->
    <el-dialog :title="form.id ? '编辑课程分类' : '添加课程分类'" :visible.sync="teacherShow" width="30%" @close="cancel">
      <el-form :model="form" ref="form" label-width="100px" class="demo-ruleForm" :rules="rules">
        <el-form-item label="课程分类:" prop="sortName">
          <el-input @focus="clearValidate('sortName')" placeholder="请输入课程分类" v-model="form.sortName"
                    autocomplete="off"></el-input>
        </el-form-item>
        <!-- 状态 -->
        <div class="status">
          <div class="name">状态:</div>
          <el-switch v-model="switchStatus" active-text="启用" inactive-text="停用"></el-switch>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="cancel">取 消</el-button>
        <el-button size="mini" type="primary" @click="resetForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {courseSortPage, courseSortAddOnSet, courseSortDel} from '../../apis/course'

export default {
  data() {
    return {
      tableData: [],
      query: {
        current: 1,
        size: 10,
      },
      total: null,
      teacherShow: false, // 添加对话框
      form: {
        sortName: '',
        status: 0, // 讲师介绍
      },
      switchStatus: true,
      rules: {
        sortName: [{required: true, message: '请输入课程类型', trigger: 'blur'}]
      },
      loading: false
    };
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      const res = await courseSortPage(this.query)
      const {records, total, current, size} = res.data
      this.tableData = records
      this.total = total
      this.query.current = current
      this.query.size = size
      setTimeout(() => {
        this.loading = false
      }, 50)
    },

    async handlerRemove(item) {
      this.$confirm('此操作将永久删除课程分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const res = await courseSortDel({id: item.id})
        this.query.current = 1
        this.$message({message: '删除成功', type: "success"});
        this.getData()
      }).catch(err => {
      })
    },

    handleSizeChange(size) {
      this.query.size = size
      this.getData()
    },

    handleCurrentChange(page) {
      this.query.current = page
      this.getData()
    },

    cancel() {
      this.teacherShow = false
      this.switchStatus = true
      this.clearCen()
    },

    handlerEdit(item) {
      this.teacherShow = true
      this.form.id = item.id
      this.switchStatus = item.status == 0 ? true : false
      this.form.status = item.status
      this.form.sortName = item.sortName
    },

    resetForm() {
      this.$refs.form.validate(async val => {
        if (!val) return
        this.form.status = this.switchStatus ? 0 : 1  // 是否禁用
        await courseSortAddOnSet(this.form)
        this.$message({message: this.form.id ? '编辑成功' : '添加成功', type: "success"});
        this.teacherShow = false
        this.getData()
        this.clearCen()
      })
    },

    // 清空内容 初始化值
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      delete this.form.id // 删除对象里面的id
    },

    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },
  }
};
</script>

<style lang="less" scoped>
.name {
  display: flex;
  width: 100px;
  padding-right: 12px;
  justify-content: flex-end;
}

.status {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

/deep/ .el-dialog {
  position: relative;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0 !important;
}
</style>